<template>
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <b-card class="mt-5 border-0 shadow-0">
          <h3>Ajout d'un nouvel agent</h3>
          <b-card-text>
          </b-card-text>

          <b-card-text>
            <form class="">
              <h5 class="mt-2">Etat civil</h5>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label>Nom</label>
                  <b-form-input type="text" v-model="editableItem.nom" name="nom" placeholder="Nom" />
                </div>
                <div class="col-sm-6 form-group">
                  <label>Prénom </label>
                  <b-form-input type="text" v-model="editableItem.prenom" name="prenom" placeholder="Prénom" />
                </div>
                <div class="col-sm-12 form-group">
                  <label> Sexe  </label>
                  <b-form-select v-model="editableItem.sexe" :options="sexeOptions">
                    <!-- <template #first>
                      <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                    </template> -->
                  </b-form-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label>Email</label>
                  <b-form-input type="text" v-model="editableItem.email" name="email" placeholder="Email" />
                </div>
                <div class="col-sm-6 form-group">
                  <label> Contact </label>
                  <b-form-input type="text" v-model="editableItem.phone" name="phone" placeholder="Contact" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label>Adresse</label>
                  <b-form-input type="text" v-model="editableItem.adresse" name="email" placeholder="Adresse" />
                </div>
                <div class="col-sm-6 form-group">
                  <label> Code Postal </label>
                  <b-form-input type="text" v-model="editableItem.codePostal" name="codePostal" placeholder="Code postal" />
                </div>
              </div>
              <h5 class="mt-3 mb-2">Etat civil</h5>
              <div class="row">
                <div class="col-sm-12 form-group">
                  <label>Matricule</label>
                  <b-form-input type="text" v-model="editableItem.matricule" name="matricule" placeholder="Matricule" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 form-group">
                  <label> Antenne  </label>
                  <b-form-select placeholder="Entrer le nom de la antenne" v-model="editableItem.antenne" :options="antenneOptions"></b-form-select>
                  <!-- <input type="text" v-model="editableItem.libelle" class="form-control style_form_control" name="libelle" placeholder="Libellé du sous-acte"> -->
                </div>
                <div class="col-sm-6 form-group">
                  <label> Fonction  </label>
                  <b-form-select placeholder="Entrer le nom de la fonction" v-model="editableItem.fonction" :options="fonctionOptions"></b-form-select>
                </div>
              </div>
            </form>

          </b-card-text>
          <div class="d-flex justify-content-end">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm"  class="style_btn btn mr-2 rounded-0" @click="cancel()">
              Annuler
            </b-button>
            <b-button size="sm"  class="style_btn btn rounded-0 btn  btn-success" @click.prevent="submit()">
              Valider
            </b-button>
          </div>
        </b-card>
      </div>
    </div>        
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: {
      title: {
        type:  String,
        required: true
      },
      item: {
        type:  Object,
        default: () => ({ })
      }
    },
    data () {
      return {
        editableItem: {}
      }
    },
    created () {
      this.fetchAntennes({
        pageSize: 100
      })
      this.fetchFonctions({
        pageSize: 100
      })
    },
    watch: {
      item () {
        this.editableItem = { ...this.item, sexe: null }
      }
    },
    computed: {
      ...mapGetters({
        antennes: 'antenne/antennes',
        fonctions: 'fonction/fonctions',
        agents: 'agent/agents'
      }),
      antenneOptions () {
        return this.antennes.map(item => ({ value: item, text: item.nom }) )
      },
      sexeOptions () {
        return ['M', 'F'].map(item => ({ value: item, text: item }) )
      },
      fonctionOptions () {
        return this.fonctions.map(item => ({ value: item, text: item.nom }) )
      },
    },
    methods: {
      ...mapActions({
        fetchAntennes: 'antenne/fetchAntennes',
        fetchFonctions: 'fonction/fetchFonctions',
        createOrUpdateAgent: 'agent/createOrUpdateAgent',
      }),
      submit () {
        this.updateOrCreateAgent(this.editableItem)
      },
      updateOrCreateAgent (agent) {
        console.log(agent)
        this.createOrUpdateAgent(agent).then(data => {
          console.log(data)
          this.$toast.success("Opération réussie!", {
              position: 'bottom-right',
              duration: 5000
            })
        })
      },
      cancel () {
        this.$emit('canceled')
      }
    }
    
  }
</script>